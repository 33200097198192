.my_container {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding-top: 45px;
  padding-bottom: 120px;

  .container_inner {
    width: 1460px;
    min-height: 1200px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 80px;

    .my_header {
      font-size: 30px;
      font-weight: 900;
    }

    .list_item {
      height: 257px;

      .list_item_box {
        float: left;
      }
    }

    .list_item::after {
      content: '';
      display: block;
      clear: both;
    }

    .no_result {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      margin-top: 60px;

      p {
        font-size: 20px;
        font-weight: 700;
      }

      button {
        width: 240px;
        height: 40px;
      }
    }
  }
}

.my_list_item {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  gap: 12px;
}

@media (max-width: 1440px) {
  .my_container {
    .container_inner {
      width: 100%;
      padding: 0 20px;
    }
  }

  .my_list_item {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .my_container {
    .container_inner {
      padding: 0;
      .my_header {
        padding: 0 10px;
        // font-size: 20px;
      }
      .no_result {
        p {
          font-size: 16px;
        }
        button {
          width: 200px;
          height: 30px;
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .my_list_item {
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
}
