.search_container {
    border-top: 1px solid #cccccc;

    // padding-bottom: 120px;

    .container_inner {
        width: 1460px;
        min-height: 800px;
        margin-left: auto;
        margin-right: auto;

        .search_header {
            margin-top: 45px;
            font-size: 32px;
            font-weight: 700;

            .search_name {
                color: #1e69cb;
            }
        }

        .search_header.no_result {
            text-align: center;
        }

        .list_container {
            .list_item {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 10px;
                justify-items: center;
                align-items: center;
                margin-bottom: 60px;

                .list_item_box {
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    justify-items: center;
                    align-content: space-between;
                }
            }
        }

        .search_desc {
            margin-top: 72px;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
        }
    }
}

@media (max-width: 1440px) {
    .search_container {
        .container_inner {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;

            .list_container {
                .list_item {
                    justify-content: center;
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            .search_header {
                font-size: 28px;
            }

            .search_desc {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .search_container {
        .container_inner {
            .list_container {
                .list_item {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 2px;
                }
            }

            .search_header {
                font-size: 20px;
            }
            .search_desc {
                font-size: 14px;
            }
        }
    }
}
