.main_list_li {
    width: 100%;
    height: 207px;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.4s;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    // margin: 0 10px 0 10px;

    .main_list_thumb {
        width: 100%;
        height: 100%;
        position: relative;

        .main_list_bg {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 2px 2px 0 2px;
            box-sizing: border-box;
            border-radius: 15px;
            transition: transform 0.4s;
            filter: brightness(60%);
        }

        .main_list_logo {
            position: absolute;
            width: 90px;
            height: 90px;
            top: 10px;
            left: 10px;
            border-radius: 45px;
            z-index: 6;
        }

        .main_list_users {
            display: flex;
            align-items: center;
            gap: 10px;
            padding-left: 10px;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 90px;
            height: 30px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 30px;
            display: flex;
            z-index: 6;

            img {
                width: 20px;
                height: 20px;
            }

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;

                font-weight: 400;
                font-size: 16px;

                span {
                    font-weight: 700;
                }
            }
        }

        // 여기 수정해야 합니다
        .main_list_name {
            position: absolute;
            left: 10px;
            bottom: 60px;
            color: white;
            font-size: 24px;
            font-weight: 700;
            z-index: 6;
            display: inline-block;
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .main_list_profile {
            position: absolute;
            left: 10px;
            bottom: 10px;
            height: 40px;
            display: flex;
            align-items: center;
            z-index: 6;

            img {
                width: 40px;
                height: 40px;
                border: 1px solid #ffffff;
                border-radius: 100%;
            }

            span {
                margin-left: 9px;
                color: white;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }

    .edit_menu {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 10px;
        position: relative;

        figure {
            width: 20px;
            height: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        span {
            overflow: hidden;
            margin-left: 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 66%;
        }

        button {
            margin-left: auto;
            width: 90px;
            height: 40px;
        }
    }
}

.edit_menu {
    width: 345px;
    display: flex;
    align-items: center;

    height: 40px;
    margin-top: 10px;
    position: relative;

    figure {
        width: 20px;
        height: 20px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    span {
        overflow: hidden;
        margin-left: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 66%;
    }

    button {
        margin-left: auto;
        width: 90px;
        height: 40px;
    }
}

.main_list_li_info {
    width: 365px;
    height: 414px;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.3s;
    flex-grow: 0;
    flex-shrink: 0;
    box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 105;
    box-sizing: border-box;

    .main_list_thumb {
        width: 100%;
        height: 50%;
        position: relative;

        .main_list_bg {
            position: absolute;
            width: 100%;
            height: 100%;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            transition: transform 0.4s;
            padding: 2px 2px 0 2px;
            box-sizing: border-box;
            background-color: white;
            filter: brightness(60%);
        }

        .main_list_logo {
            position: absolute;
            width: 90px;
            height: 90px;
            top: 10px;
            left: 10px;
            border-radius: 45px;
        }

        .main_list_users {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 90px;
            height: 30px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 30px;
            display: flex;

            img {
                width: 20px;
                height: 20px;
                margin-top: 5px;
                margin-left: 13px;
            }

            div {
                color: white;
                margin-left: 13px;
                margin-right: 3px;
                margin-top: 3px;
                font-weight: 400;
                font-size: 16px;

                span {
                    font-weight: 700;
                }
            }
        }

        .main_list_name {
            position: absolute;
            left: 10px;
            bottom: 60px;

            color: white;
            font-size: 24px;
            font-weight: 700;
        }

        .main_list_profile {
            position: absolute;
            left: 10px;
            bottom: 10px;
            height: 40px;
            display: flex;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
                border: 1px solid #ffffff;
                border-radius: 100%;
            }

            span {
                margin-left: 9px;
                color: white;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }

    .main_list_detail {
        padding: 2px 2px 0 2px;
        box-sizing: border-box;
        width: 100%;
        height: 207px;
        background-color: white;
        position: absolute;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        .main_list_detail_text_wrap {
            padding-top: 20px;
            padding-left: 10px;
            font-size: 16px;
            font-weight: 400;

            p:first-child {
                font-weight: 700;
            }
        }

        button {
            width: 120px;
            height: 40px;
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translateX(-50%);
            background: #fee500;
            border-radius: 20px;
        }

        button:hover {
            background: #fff280;
        }
    }
}

@media (max-width: 1210px) {
    .main_list_li {
        height: 180px;
        .main_list_thumb {
            .main_list_logo {
                width: 60px;
                height: 60px;
            }
            .main_list_users {
                img {
                    width: 16px;
                    height: 16px;
                }
                div {
                    font-size: 14px;
                }
            }
            .main_list_name {
                font-size: 18px;
            }
            .main_list_profile {
                img {
                    width: 30px;
                    height: 30px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }

    .edit_menu {
        width: 280px;
    }

    .main_list_li_info {
        width: 270px;
    }
}

@media (max-width: 765px) {
    .main_list_li {
        height: 150px;
        .main_list_thumb {
            .main_list_logo {
                width: 40px;
                height: 40px;
            }
        }
    }

    .edit_menu {
        width: 210px;
    }
}
