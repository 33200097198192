body,
input,
a,
button {
  margin: 0;
  padding: 0;
}

body * {
}

.solid {
  border-style: solid;
}

.dashed {
  border-style: dashed;
}

.block {
  display: block;
}

.blind {
  display: none;
}

.webgl-upload-container {
  width: 100%;
  height: 100vh;
  overflow: auto;

  .webgl-upload-container-inner {
    width: 80%;
    min-height: 400px;
    margin: 0 auto 0 auto;
    padding: 100px 0 100px 0;
    color: #333333;

    .header {
      display: flex;
      justify-content: space-between;

      .inp-wrap-inner {
        margin-right: 20px;

        select {
          height: 100%;
          padding: 5px;
          font-size: 20px;
          font-weight: 700;
        }
      }

      .inp-wrap {
        display: flex;

        .inp-label {
          margin-right: 20px;
          font-size: 24px;
          font-weight: 700;
        }

        input {
          width: 300px;
          height: 40px;
          padding: 10px;
          box-sizing: border-box;
          font-size: 20px;
          border-color: #333333;
          border-radius: 8px;
        }

        label {
          margin-right: 15px;
        }

        button {
          background: none;
          cursor: pointer;
          border: 2px solid #333333;
          padding: 5px 10px 5px 10px;
          box-sizing: border-box;
          font-size: 20px;
          font-weight: 700;
          margin-right: 20px;
        }

        button:hover {
          cursor: pointer;
          background-color: #e5e5e5;
          color: #1e69cb;
          border-color: #1e69cb;
        }
      }

      .btn-wrap {
        .webgl-label {
          border: 2px solid #333333;
          padding: 5px 10px 5px 10px;
          box-sizing: border-box;
          font-size: 20px;
          font-weight: 700;
          margin-right: 20px;
        }

        .webgl-label:hover {
          cursor: pointer;
          background-color: #e5e5e5;
          color: #1e69cb;
          border-color: #1e69cb;
        }

        #webgl-input {
          display: none;
        }

        button {
          padding: 5px 10px 5px 10px;
          border: 2px solid #333333;
          background-color: transparent;
          font-size: 20px;
          font-weight: 700;
        }

        button:hover {
          cursor: pointer;
          background-color: #e5e5e5;
          color: #1e69cb;
          border-color: #1e69cb;
        }
      }
    }

    .body {
      width: 100%;
      min-height: 600px;
      border-width: 3px;
      border-color: #333333;
      box-sizing: border-box;
      padding: 20px;
      margin-top: 20px;
      position: relative;

      .dragOnText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        font-weight: 700;
        text-align: center;
      }

      .body-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        :first-child {
          position: relative;
          width: 130px;
          text-align: center;
        }

        :first-child::after {
          content: '';
          display: block;
          position: absolute;
          background-color: black;
          width: 1px;
          height: 14px;
          top: 0;
          right: 0;
        }

        :nth-child(2) {
          width: 60px;
          text-align: center;
        }

        div {
          position: relative;
          box-sizing: border-box;
        }

        div::after {
          content: '';
          display: block;
          position: absolute;
          background-color: black;
          width: 1px;
          height: 14px;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }

        :nth-child(3) {
          width: 1050px;
          text-align: center;
        }

        :nth-child(4) {
          width: 150px;
          text-align: center;
        }
      }

      .body-list {
        .file-list-item {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          .inp-wrap {
            position: relative;
            width: 90px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            .item-delete-btn {
              position: absolute;
              cursor: pointer;
              left: 0;
              width: 15px;
              height: 15px;
              padding: 3px;
              border-radius: 4px;
              color: #333333;
            }

            .item-delete-btn:hover {
              color: white;
              background-color: #1e69cb;
            }
          }

          :first-child {
            width: 130px;
            text-align: center;
          }

          :nth-child(2) {
            width: 60px;
            text-align: center;
            font-size: 22px;
          }

          :nth-child(3) {
            width: 1050px;
            text-align: center;
            font-size: 22px;
          }

          :nth-child(4) {
            width: 150px;
            text-align: center;
            font-size: 22px;
          }
        }
      }
    }

    .footer {
      margin-top: 50px;

      .inp-wrap {
        label {
          font-size: 20px;
          font-weight: 700;
        }

        .desc-input {
          width: 100%;
          height: 40px;
          padding: 10px;
          box-sizing: border-box;
          font-size: 20px;
          border: 1px solid #333333;
          border-radius: 8px;
          margin-top: 10px;
        }
      }

      .btn-wrap {
        position: relative;
        margin-top: 20px;

        button {
          cursor: pointer;
          position: relative;
          top: 0;
          left: calc(100% - 100px);
          width: 100px;
          height: 50px;
          border: 2px solid #333333;
          background-color: transparent;
          font-size: 18px;
          font-weight: 700;
        }

        button:hover {
          background-color: #e5e5e5;
          color: #1e69cb;
          border-color: #1e69cb;
        }
      }
    }

    .result-container {
      position: relative;
      padding: 10px;
      margin-top: 60px;
      border: 4px solid #333333;
      border-radius: 5px;
      box-sizing: border-box;

      .result-inner {
        position: relative;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
      }
    }

    .result-container:hover {
      cursor: pointer;
      border-color: #8b949e;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .result-container:hover::before {
      content: '클릭해서 복사하기';
      position: absolute;
      top: -58px;
      left: 50%;
      text-align: center;
      transform: translateX(-50%);
      font-size: 24px;
      padding: 6px;
      box-sizing: border-box;
      border-radius: 7px;
      background-color: #8b949e;
      color: white;
    }

    .result-container:hover::after {
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      color: #8b949e;
      pointer-events: none;
      content: '';
      border: 6px solid #8b949e;
      margin-right: -6px;
    }
  }
}

.loading-container {
  position: absolute;
  width: 1000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  padding: 10px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #333333;
}

.dot {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot div {
  width: 40px;
  height: 40px;
  margin: 0 10px 0 10px;
  border-radius: 20px;
  background-color: #333333;
  transform: scale(0);
  animation: dot 1.5s linear infinite;
}

.dot div:nth-child(1) {
  left: -100px;
  animation-delay: -0.1s;
}

.dot div:nth-child(3) {
  right: -100px;
  animation-delay: 0.1s;
}

@keyframes dot {
  0% {
    transform: scale(0);
  }
  35% {
    transform: scale(1);
  }
  70% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
