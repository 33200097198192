.footer_container {
    width: 100%;
    margin-top: 45px;
    margin-bottom: 64px;
    box-sizing: border-box;

    .container_inner {
        width: 1440px;
        margin-left: auto;
        margin-right: auto;

        .box_item_wrapper {
            display: flex;
            justify-content: space-between;

            .item_info {
                display: flex;
                flex-direction: column;
                gap: 22px;
                img {
                    width: 70px;
                }
                .text_wrapper {
                    .info_text {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .text_wrapper:last-child {
                    margin-bottom: 26px;
                }
            }

            .nav_list {
                display: flex;
                gap: 60px;
                justify-content: flex-end;

                .nav_wrapper {
                    width: 150px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .nav_header {
                        line-height: 200%;
                        font-size: 16px;
                        font-weight: 700;
                    }

                    .nav_header:hover {
                        color: #1e69cb;
                    }

                    .nav_item {
                        line-height: 200%;
                        font-size: 14px;

                        a {
                            display: block;
                        }
                    }
                }
            }
        }

        hr {
            margin-top: 10px;
            margin-bottom: 14px;
            border-bottom: none;
            border-top: solid 1px rgb(197, 197, 197);
        }

        .notice_wrapper {
            display: flex;
            justify-content: space-between;
            padding-bottom: 64px;

            .copy_right {
                font-size: 16px;
                font-weight: 400;
            }

            .language_wrapper {
                display: flex;
                justify-content: space-between;

                button {
                    background: none;
                    font-weight: 400;
                }

                button:not(:first-child) {
                    margin-left: 26px;
                }

                .active {
                    font-weight: 700;
                }
            }
        }
    }
}

@media (max-width: 1440px) {
    .footer_container {
        .container_inner {
            width: 100%;
            margin: 0;

            .box_item_wrapper {
                padding: 0 16px;

                .item_info {
                    .text_wrapper {
                        .info_text {
                            font-size: 12px;
                        }
                    }
                }
            }

            .notice_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 16px;
                padding-bottom: 64px;

                .copy_right {
                    font-size: 12px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .footer_container {
        .container_inner {
            .box_item_wrapper {
                .item_info {
                    width: 100%;
                }
                .nav_list {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .footer_container {
        .container_inner {
            .notice_wrapper {
                display: flex;
                align-items: baseline;
            }
        }
    }
}
