.header_container {
  height: 90px;
  transition: 0.3s;

  width: 100%;
  background: #fff;

  .header_inner {
    padding: 0 15%;
    height: 100%;
    margin: 0 auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header_logo {
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .header_nav {
      display: flex;
      gap: 50px;

      .header_nav_btn_wrap {
        display: flex;
        justify-content: flex-end;
        gap: 50px;
        width: max-content;

        button {
          background: none;
          font-size: 20px;
          font-weight: 700;
        }

        button:hover {
          color: #1e69cb;
        }

        button.active {
          color: #1e69cb;
        }
      }

      .header_nav_menu_wrap {
        display: flex;
        justify-content: space-around;
        width: 550px;
        // margin-left: 45px;

        .header_search_bar {
          width: 240px;
          height: 40px;
          position: relative;

          form {
            width: 100%;
            height: 100%;
          }

          input {
            padding: 5px 30px 5px 10px;
          }

          img {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }

        .header_create_btn,
        .header_login_btn {
          width: 120px;
          height: 40px;
          font-size: 20px;
          font-weight: 700;
          border-radius: 20px;
          text-align: center;
          line-height: 40px;

          a {
            display: block;
          }
        }

        .header_my {
          width: 150px;
          height: 40px;
          background-color: #fee500;
          border-radius: 20px;
          position: relative;
          text-align: center;
          z-index: 100;
          padding-right: 16px;
          padding-left: 50px;
          transition: all 0.3s;

          .header_my_thumbnail {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            position: absolute;
            left: 0;
          }

          .header_nickname {
            margin-left: auto;
            width: 90px;
            font-size: 16px;
            font-weight: 700;
            line-height: 40px;
            cursor: pointer;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .header_nickname:hover + .header_my_menu {
            display: block;
          }
        }

        .header_my_menu {
          display: none;
          width: 150px;
          position: absolute;
          right: 0;
          top: 50;

          background: #ffffff;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
          border-radius: 20px;
          z-index: 1;

          .header_my_menu_info {
            margin-top: 20px;
            margin-left: 14px;
            margin-right: 12px;
            text-align: left;
            position: relative;

            .header_my_menu_nickname {
              font-size: 16px;
              font-weight: 700;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 98px;
            }

            .header_my_menu_email {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 12px;
              font-weight: 400;
            }

            .header_my_menu_edit {
              display: block;
              width: 18px;
              height: 18px;
              position: absolute;
              top: 0;
              right: 0;
              background: url(../assets/ui/my_edit_pack.png) no-repeat 0 0 / auto 101%;
            }

            .header_my_menu_edit:hover {
              background-position-x: 100%;
            }
          }
        }

        .header_my_menu:hover {
          display: block;
        }
      }
    }
  }
}

.header_my {
  width: 150px;
  height: 40px;
  background-color: #fee500;
  border-radius: 20px;
  position: relative;
  text-align: center;
  z-index: 100;
  padding-right: 16px;
  padding-left: 50px;
  transition: all 0.3s;

  .header_my_thumbnail {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: absolute;
    left: 0;
  }

  .header_nickname {
    margin-left: auto;
    width: 90px;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .header_nickname:hover + .header_my_menu {
    display: block;
  }

  .header_my_menu {
    // display: none;
    width: 150px;
    position: absolute;
    right: 0;
    top: 50;

    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    z-index: 1;

    .header_my_menu_info {
      margin-top: 20px;
      margin-left: 14px;
      margin-right: 12px;
      text-align: left;
      position: relative;

      .header_my_menu_nickname {
        font-size: 16px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 98px;
      }

      .header_my_menu_email {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 400;
      }

      .header_my_menu_edit {
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 0;
        right: 0;
        background: url(../assets/ui/my_edit_pack.png) no-repeat 0 0 / auto 101%;
      }

      .header_my_menu_edit:hover {
        background-position-x: 100%;
      }
    }
  }
}

@media (max-width: 1800px) {
  .header_container {
    .header_inner {
      padding: 0 10%;

      .header_logo {
        width: 200px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .header_container {
    width: 100%;

    .header_inner {
      width: 100%;
      padding: 10px;

      .header_logo {
        width: 140px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .header_container {
    height: 78px;
  }
}

@media (max-width: 1024px) {
  .header_container {
    height: 60px;
    .header_inner {
      .header_logo {
        width: 140px;
      }
      .header_nav {
        display: none;
      }
    }
  }

  .header_create_btn {
    width: 50% !important;
  }

  .header_my {
    width: 50%;
    &:hover .header_my_menu {
      display: block;
    }

    .header_nickname {
      width: 100%;
    }
  }

  .header_my_menu {
    display: none;
    right: 0;

    .header_my_menu_btn_wrap {
      width: 100%;
    }
  }
}
