.container {
    width: 100%;
    height: 340px;
    background-color: #1e69cb;
    padding: 0 340px;
    display: flex;

    .container_inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text_wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            .text_item {
                color: white;
                letter-spacing: -0.015em;
            }

            .text_main {
                font-size: 32px;
                font-weight: 700;
            }

            .text_sub {
                font-size: 20px;
                font-weight: 400;
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 20px;
                li {
                    list-style: disc;
                    list-style-position: inside;
                    color: white;
                }
            }
        }

        .create_btn {
            display: flex;
            justify-content: center;
            align-items: center;

            width: max-content;
            height: 100%;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 290px;
                height: 56px;
            }
        }
    }
}

@media (max-width: 1700px) {
    .container {
        padding: 0 40px;

        .container_inner {
            .text_wrapper {
                .text_main {
                    font-size: 28px;
                }
                .text_sub {
                    font-size: 18px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .container {
        height: max-content;

        .container_inner {
            padding: 20px 0;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 30px;

            .text_wrapper {
                .text_main {
                    font-size: 18px;
                }
                .text_sub {
                    font-size: 16px;
                }
                ul {
                    gap: 8px;
                }
            }

            .create_btn {
                a {
                    font-size: 18px;
                    width: 200px;
                    height: 46px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .container {
        .container_inner {
            .create_btn {
                a {
                    font-size: 14px;
                    width: 160px;
                    height: 46px;
                }
            }
        }
    }
}

