.terms_container {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    min-height: 990px;
    padding-top: 90px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;

    .container_inner {
        width: 1440px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 45px;
        padding-bottom: 45px;

        .terms_header {
            font-size: 24px;
            font-weight: 700;
        }

        .terms_box {
            margin-top: 39px;
            margin-bottom: 45px;

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }
}

@media (max-width: 1440px) {
    .terms_container {
        .container_inner {
            width: 100%;
            padding: 50px 20px;
        }
    }
}
