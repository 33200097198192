.unity_loading_bar {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    figure {
        position: relative;
        height: calc(100% - 100px);

        .unity_loading_img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .unity_loading_logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 300px;
            height: 300px;
            object-fit: contain;
        }

        @media screen and (max-width: 768px) {
            .unity_loading_text_1 {
                left: 15px;
                width: 335px;
                height: 39px;
                bottom: 70px;
            }
        }

        .unity_loading_text_3 {
            position: absolute;
            left: 56px;
            bottom: 34px;
            color: white;
            font-size: 16px;
            font-weight: 500;
        }

        @media screen and (max-width: 768px) {
            .unity_loading_text_3 {
                left: 15px;
                bottom: 10px;
            }
        }
    }

    .animation_container {
        background-color: black;
        position: relative;
        width: 100%;
        height: 100px;
        border-top: 3px solid white;
        box-sizing: border-box;

        .bounce {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .unity_loading_plika_logo {
            position: absolute;
            left: 19px;
            bottom: 30px;
            width: 202px;
            height: 30px;
        }
    }
}

.container {
    width: 100%;
    height: 100vh;
}

.unity_container {
    position: fixed;
    width: 100%;
    height: 100%;

    input[placeholder^='메시지를 입력하세요'] {
        height: 0 !important;
        border: 0 !important;
    }
}
.unity_logo_menu {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 105;

    .unity_logo_menu_img {
        width: 40px;
        height: 40px;
        background: url(../assets/ui/plika_menu_logo_pack.png) no-repeat 0 0 /
            auto 100%;
    }

    .unity_logo_menu_img:hover {
        background-position-x: 100%;
        cursor: pointer;
    }

    .unity_logo_menu_img.active {
        background-position-x: 100%;
    }

    .unity_logo_menu_box {
        background: #ffffff;
        border-radius: 20px;
        position: absolute;
        top: calc(100% + 5px);
        width: 150px;
    }
}

.unity_info_bar {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    position: relative;

    &:hover > div {
        display: flex;
    }

    .unity_info_name {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 40px;
        border-radius: 30px;
        background: rgba(0, 0, 0, 0.5);
    }

    .unity_info_players {
        display: flex;
        align-items: center;
        padding-left: 16px;

        width: 120px;
        height: 40px;
        border-radius: 30px;
        background: rgba(0, 0, 0, 0.5);
        font-weight: 400;

        transition: all 0.3s;

        &:hover svg {
            filter: drop-shadow(0px 0px 5px #fff);
        }

        svg {
            width: 30px;
            height: 30px;
            margin-right: 9px;
            transition: all 0.3s;
        }

        figure {
            width: 30px;
            height: 30px;
            margin-right: 15px;

            .icon.att {
                width: 100%;
                height: 100%;
                background-position-x: 0;
                cursor: pointer;
            }

            .icon.att.active {
                background-position-x: 100%;
            }

            .icon {
                display: inline-block;
                border-radius: 50%;
                background: url(../assets/ui/user_group_icon_pack.png) no-repeat
                    0 0 / auto 101%;
                vertical-align: top;
            }
        }

        span {
            font-weight: 700;
        }
    }

    // .unity_info_players.active {
    //     color: #fee500;
    // }

    .unity_info_players:hover {
        cursor: pointer;
        text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff;
        .icon.att {
            background-position-x: 100%;
        }
    }

    // .unity_info_player.active {
    //     color: #fee500;
    // }
}

.unity_side_menu {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;

    .unity_exit_btn {
        position: relative;
        top: 20px;
        margin-right: 20px;
        width: 40px;
        height: 40px;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .unity_exit_btn:hover {
        color: #fee500;
    }

    .unity_players_info_menu,
    .unity_chat_menu {
        width: 360px;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);

        .unity_menu_header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            height: 60px;
            margin: 0 15px;
            border-bottom: 1px solid #ffffff;
            padding-bottom: 4px;
            box-sizing: border-box;
            color: #ffffff;

            .unity_menu_header_name {
                font-weight: 700;
                font-size: 24px;
            }

            .unity_menu_header_btn {
                font-size: 30px;
            }
        }
    }

    .unity_players_info_menu {
        .unity_players_info_menu_body {
            margin-top: 21px;
            margin-left: 15px;
            margin-right: 15px;

            .unity_players_info_menu_body_type {
                color: #ffffff;
                font-weight: 700;
                font-size: 20px;
            }

            .unity_players_info_item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 15px;
                position: relative;

                .unity_players_info_item_img_box {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    overflow: hidden;

                    figure {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }

                    .unity_players_info_item_onoff {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 30px;
                        height: 15px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #ffffff;
                        border-radius: 10px;
                        color: #ffffff;
                        font-size: 10px;
                        font-weight: 700;
                        text-align: center;
                    }

                    .unity_players_info_item_onoff.on {
                        background: #1e69cb;
                    }

                    .unity_players_info_item_onoff.off {
                        background: #ee3030;
                    }
                }

                .unity_players_info_item_text_box {
                    display: flex;
                    flex-direction: column;
                    color: #ffffff;
                    flex-grow: 1;
                    margin-left: 10px;

                    // .unity_players_info_item_nickname {
                    //   font-size: 16px;
                    //   font-weight: 700;
                    // }

                    // .unity_players_info_item_email {
                    //   font-size: 12px;
                    //   font-weight: 400;
                    // }
                }

                .unity_player_info_item_auth {
                    width: 60px;
                    height: 24px;
                    font-size: 12px;
                    font-weight: 700;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 36px;
                }

                .unity_player_info_item_auth.member:hover {
                    cursor: pointer;
                }

                .unity_player_info_item_menu_box {
                    position: absolute;
                    top: 100%;
                    right: 150px;
                    font-size: 16px;

                    .item_menu_title {
                        height: 30px;
                        padding-left: 14px;
                        color: #cccccc;
                    }
                }
            }
        }
    }

    .unity_chat_menu {
        display: flex;
        flex-direction: column;

        .unity_chat_menu_body {
            flex-grow: 1;
            margin: 15px 15px;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            max-height: calc(100% - 60px - 90px);
            overflow-y: auto;
            overflow-x: hidden;

            .unity_chat_menu_item {
                width: 100%;
                display: flex;
                padding-right: 15px;
                margin-top: 15px;
                box-sizing: border-box;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                .unity_chat_menu_item_info {
                    width: 100%;
                    margin-left: 15px;
                    color: #ffffff;

                    .unity_chat_menu_item_info_box {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;

                        .unity_chat_item_nickname {
                            font-size: 16px;
                            font-weight: 700;
                        }

                        .unity_chat_item_time {
                            color: #cccccc;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }

                    .unity_chat_item_body {
                        width: 100%;
                        font-size: 14px;
                        font-weight: 400;
                        word-wrap: break-word;
                        word-break: break-all;
                    }
                }
            }
        }

        .unity_chat_menu_body::-webkit-scrollbar {
            width: 10px;
        }

        .unity_chat_menu_body::-webkit-scrollbar-thumb {
            background: #ffffff;
            border-radius: 7px;
        }

        .unity_chat_menu_input {
            margin: 0 15px;
            padding-top: 10px;
            padding-bottom: 40px;
            border-top: 1px solid #ffffff;

            form {
                display: flex;
                align-items: center;
                width: 100%;

                input {
                    height: 40px;
                    background: transparent;
                    color: #ffffff;
                    border: 1px solid #ffffff;
                    border-radius: 20px;
                    flex-grow: 1;
                    padding: 3px 12px;
                    box-sizing: border-box;
                }

                button.unity_chat_send_btn {
                    width: 24px;
                    height: 24px;
                    margin-left: 12px;
                    margin-right: 4px;
                    background: url(../assets/ui/chat_send_icon_pack.png)
                        no-repeat 0 0 / auto 101%;
                    cursor: pointer;
                }
            }
        }
    }
}

.unity_user_menu_container {
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 115;

    .user_info {
        width: 180px;
        height: 40px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 30px;
        display: flex;
        position: relative;

        &:hover .user_nickname,
        &:hover .user_email {
            color: #fff;
            text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff;
        }
        &:hover > div {
            display: flex;
        }

        figure {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            border: solid 2px #fff;

            img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                object-fit: cover;
            }
        }

        .user_info_text_box {
            margin-left: 10px;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 1.4;

            .user_nickname {
                font-size: 14px;
                font-weight: 700;
                transition: all 0.3s;
            }

            .user_email {
                font-size: 10px;
                font-weight: 400;
                transition: all 0.3s;
            }
        }
    }

    .user_info:hover {
        cursor: pointer;

        .user_info_text_box {
            color: #fee500;
        }
    }

    .user_menu_btn_box {
        display: flex;
        gap: 15px;

        figure {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background: rgba(0, 0, 0, 0.5);

            &:hover > div {
                display: flex;
            }

            svg {
                width: 30px;
                height: 30px;
                transition: all 0.3s;
                color: #fff;
            }
        }

        .icon.att:hover svg {
            filter: drop-shadow(0px 0px 5px #fff);
        }

        .icon.att.disabled svg {
            color: #ee3030;
        }

        .icon.att.disabled:hover svg {
            color: #ee7070;
            filter: unset;
        }

        .icon.att {
            .activex_icon {
                color: #ee3030;
            }

            &:hover .activex_icon {
                color: #ee7070;
                filter: initial;
            }
        }

        // figure:not(:first-child) {
        //     margin-left: 15px;
        // }
    }
}

input[placeholder^='메시지를 입력하세요'] {
    height: 0 !important;
    border: 0 !important;
}
input[placeholder^='lockscreen'] {
    height: 0 !important;
    border: 0 !important;
}
.unity_canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bounce {
    margin: 0 auto 0 auto;
    width: 280px;
    height: 30px;
    text-align: center;
}

.bounce > div {
    width: 30px;
    height: 100%;
    background-color: white;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.bounce .bounce1 {
    animation-delay: -0.32s;
}

.bounce .bounce2 {
    animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    .unity_info_bar {
        .unity_info_players {
            height: 30px;
            width: 80px;
            font-size: 14px;
            padding-left: 8px;

            svg {
                height: 20px;
                width: 20px;
                margin-right: 6px;
            }
        }
    }
    .unity_user_menu_container {
        width: 100%;

        .user_info {
            width: 30px;
            height: 30px;
            figure {
                width: 100%;
                height: 100%;
            }
            .user_info_text_box {
                display: none;
            }
        }
        .user_menu_btn_box {
            width: 100%;
            justify-content: space-evenly;
            align-items: center;
            gap: 6px;

            figure {
                width: 30px;
                height: 30px;

                svg {
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }
}
