.tgl {
    display: none;
}
.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
    box-sizing: border-box;
}
.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
    background: none;
}
.tgl + .tgl-btn {
    outline: 0;
    display: inline-block;
    width: 55px;
    height: 28px;
    position: relative;
    cursor: pointer;
    user-select: none;
}
.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: '';
    width: 20px;
    height: 100%;
}
.tgl + .tgl-btn:after {
    left: 0;
}
.tgl + .tgl-btn:before {
    display: none;
}
.tgl:checked + .tgl-btn:after {
    left: calc(100% - 20px);
}

.tgl-flat + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 2em;
}
.tgl-flat + .tgl-btn:after {
    transition: all 0.2s ease;
    background: #ccc;
    content: '';
    border-radius: 2em;
}
.tgl-flat:checked + .tgl-btn {
    border: 2px solid #1e69cb;
    background: #fff;
}
.tgl-flat:checked + .tgl-btn:after {
    right: 0;
    background: #1e69cb;
}
