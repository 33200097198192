* {
    outline: none;
    margin: 0;
}


#viewer-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
