@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SUIT';
  font-weight: 100;
  src: url('./styles/fonts/SUIT-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 200;
  src: url('./styles/fonts/SUIT-ExtraLight.otf') format('opentype');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 300;
  src: url('./styles/fonts/SUIT-Light.otf') format('opentype');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 400;
  src: url('./styles/fonts/SUIT-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 500;
  src: url('./styles/fonts/SUIT-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 700;
  src: url('./styles/fonts/SUIT-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 800;
  src: url('./styles/fonts/SUIT-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 900;
  src: url('./styles/fonts/SUIT-Heavy.otf') format('opentype');
}

@layer base {
  html,
  pre {
    font-family: 'SUIT', sans-serif;
  }

  :root {
    --color-primary: #1e69cb;
    --color-secondary: #fee500;
    --color-error: #ee3030;
    --color-white: #fff;
  }
}
