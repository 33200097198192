.webview_container {
  position: fixed;
  width: 100%;
  height: calc(100% - 60px);
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 100;
  backdrop-filter: blur(10px);

  .container_inner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    position: relative;
    background: #ffffff;
    border-radius: 30px;
  }
}

.close_btn {
  font-size: 30px;
}

.close_btn:hover {
  cursor: pointer;
}

.content_webview {
  .container_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 20px;
    width: 1460px;
    height: 800px;

    form {
      height: 100%;
    }

    .inner_box {
      padding: 30px;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .title_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        max-height: 40px;
        width: 100%;

        .title {
          width: 60%;
          height: 100%;

          font-size: 100%;
          font-weight: 700;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        input {
          width: 100%;
          height: 100%;
          border: 1px solid #cccccc;
          border-radius: 20px;
          padding: 0 16px;
        }
      }

      .content_body {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
        height: calc(100% - 40px);

        .content_thumb_box {
          width: 60%;
          max-width: 840px;
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .content_box_default {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            line-height: 36px;
            position: relative;
            background-color: #cccccc;
            border-radius: 20px;

            p {
              font-size: 24px;
              font-weight: 700;
              color: #fff;
            }
          }

          .content_box {
            width: 100%;
            height: 100%;
            display: block;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              position: absolute;
            }

            video {
              width: 100%;
              height: 100%;
              object-fit: contain;
              position: absolute;
            }
          }

          .content_youtube_frame {
            width: 100%;
            height: 100%;
          }

          label.content_box {
            cursor: pointer;
          }

          label.content_box:hover {
            .upload_btn {
              background-position-x: 100%;
            }
          }

          .sel_modal_wrap {
            position: absolute;
            top: 10px;
            right: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;
            height: 100%;

            .upload_btn {
              position: relative;
              width: 40px;
              height: 40px;
              background: url(../assets/ui/edit_theme_bl.png) no-repeat 0 0 / auto 101%;
              top: 0;
              border: 2px solid #ffffff;
            }
          }

          .link_btn {
            height: 9%;
            max-height: 40px;
            margin-top: 20px;
            width: min-content;

            a {
              max-width: 800px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin: 0 17px;
            }

            .link_icon {
              width: 40px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            span {
              margin-left: 9px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .link_btn.edit {
            width: min-content;
            height: 9%;
            max-height: 40px;
            max-width: 100%;

            display: flex;
            align-items: center;

            .link_icon {
              width: 40px;
              height: 40px;
              background-color: #cccccc;
              border-radius: 20px;
            }

            .link_icon.active {
              background-color: #1e69cb;
              border-radius: 20px;
              color: #ffffff;
            }

            span {
              color: #cccccc;
              font-size: 16px;
            }

            input {
              width: 45%;
              height: 100%;
              padding: 0 12px;

              border: 1px solid #000000;
              border-radius: 20px;
              box-sizing: border-box;
            }

            input:placeholder-shown {
              border-color: #cccccc;
            }

            input:focus {
              border-color: #000000;
            }
          }

          .link_btn.edit:hover {
            .link_icon {
              background-color: #1e69cb;
              color: #ffffff;
            }

            span {
              color: #1e69cb;
              font-size: 16px;
            }
          }

          .link_btn.edit.active {
            width: 100%;
          }
        }

        .content_desc {
          overflow-y: auto;

          width: 40%;
          height: 100%;

          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;

          span,
          pre {
            width: 100%;
            height: 100%;
            white-space: pre-wrap;
          }

          textarea {
            width: 100%;
            height: 100%;
            resize: none;
            border: 1px solid #cccccc;
            border-radius: 20px;
            box-sizing: border-box;
            margin: 0;
            padding: 20px;
          }

          textarea::-webkit-scrollbar {
            width: 13px;
          }

          textarea::-webkit-scrollbar-thumb {
            background: #8e8e8e;
            border-radius: 7px;
          }

          .content_youtube_uri {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .save_btn {
            margin-top: 20px;
            width: 120px;
            height: 40px;
            position: relative;
          }
        }

        .content_desc::-webkit-scrollbar {
          width: 13px;
        }

        .content_desc::-webkit-scrollbar-thumb {
          background: #8e8e8e;
          border-radius: 7px;
        }
      }
    }
  }
}

.link_btn {
  height: 40px;
  width: 200px;
  border-radius: 36px;

  a {
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 17px;
  }

  .link_icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  span {
    color: #ccc;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.link_btn.edit {
  width: min-content;
  height: 100%;
  max-height: 40px;
  max-width: 100%;

  display: flex;
  align-items: center;
  gap: 10px;

  .link_icon {
    width: 40px;
    height: 40px;
    background-color: #cccccc;
    border-radius: 20px;
  }

  .link_icon.active {
    background-color: #1e69cb;
    border-radius: 20px;
    color: #ffffff;
  }

  span {
    color: #cccccc;
    font-size: 16px;
  }

  input {
    width: 380px;
    height: 100%;
    padding: 0 12px;

    border: 1px solid #000000;
    border-radius: 20px;
    box-sizing: border-box;
  }

  input:placeholder-shown {
    border-color: #cccccc;
  }

  input:focus {
    border-color: #000000;
  }
}

.link_btn.edit:hover {
  .link_icon {
    background-color: #1e69cb;
    color: #ffffff;
  }

  span {
    color: #1e69cb;
    font-size: 16px;
  }
}

.link_btn.edit.active {
  width: 100%;
}

// .container_inner {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background: #fff;
//     border-radius: 20px;
//     width: 1460px;
//     height: 800px;
//     // padding: 30px;
// }

// .inner_box {
//     display: flex;
//     flex-direction: column;
//     gap: 20px;
// }

// .title_bar {
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     .title {
//         font-size: 24px;
//         font-weight: 700;

//         input {
//             width: 840px;
//             height: max-content;
//             border: solid 1px #ccc;
//             border-radius: 30px;
//             padding: 4px 20px;

//             &::placeholder {
//                 color: #ccc;
//             }
//         }
//     }
// }

// .content_body {
//     width: 100%;
//     height: 100%;
//     display: flex;
// }

// .content_thumb_box {
//     width: 840px;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 10px;
// }

// .content_box_default {
//     width: 100%;
//     height: 620px;
//     border-radius: 20px;
//     background: #ccc;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

//     p {
//         font-size: 24px;
//         font-weight: 700;
//         color: #fff;
//     }
// }

// .link_btn {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 15px;

//     input {
//     }

//     span {
//         font-size: 16px;
//         font-weight: 500;
//         color: #cccccc;
//     }
// }

// .link_icon {
//     width: 40px;
//     height: 40px;
//     background-color: #cccccc;
//     border-radius: 50%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.content_body {
  width: 100%;
  height: 100%;
  display: flex;
}

@media (max-width: 1460px) {
  .content_webview {
    .container_inner {
      width: 90%;
      height: calc(100% - 90px);
    }
  }
  .link_btn.edit {
    input {
      width: 40%;
    }
  }
}

@media (max-width: 1024px) {
  .content_webview {
    height: calc(100% - 120px);
    position: fixed;
    top: 60px;

    .container_inner {
      width: 100%;
      height: 100%;
      border-radius: 0;
      z-index: 200;

      .inner_box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;

        .title_bar {
          .title {
            width: calc(100% - 30px);
          }
        }

        .content_body {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 10px;
          margin: 0;

          .content_thumb_box {
            width: 100%;
            height: 50%;
            max-width: none;
            .content_box {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .content_desc {
            width: 100%;
            height: 50%;
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow-y: auto;

            textarea {
              width: 100%;
              height: 100%;
            }

            .link_btn {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              gap: 10px;

              span {
                display: flex;
                align-items: center;
                width: max-content;
              }
            }

            .save_btn {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              width: 160px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .content_webview {
    height: calc(100% - 100px);
    top: 50px;
    .container_inner {
      .inner_box {
        .content_body {
          .content_desc {
            .link_btn {
              gap: 4px;

              input {
                margin: 0;
              }
            }

            .save_btn {
              width: 120px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .content_webview {
    .container_inner {
      .inner_box {
        .title_bar {
          height: 30px;

          .title {
            input {
              font-size: 16px;
              padding: 0 14px;
            }
          }
        }

        .content_body {
          .content_thumb_box {
            .content_box_default {
              p {
                font-size: 14px;
              }
            }

            .sel_modal_wrap {
              .upload_btn {
                width: 30px;
                height: 30px;
              }
            }
          }

          .content_desc {
            .link_btn {
              gap: 8px;

              .link_icon {
                width: 30px;
                height: 30px;

                svg {
                  width: 16px;
                }
              }

              span {
                font-size: 14px;
              }
            }

            .save_btn {
              height: 100%;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-height: 820px) {
  .webview_container {
    .container_inner {
      height: calc(100% - 10px);
    }
  }
}
