* {
  outline: none;
  margin: 0;
}

html,
body {
  height: 100%;
}

.blind {
  display: none;
}

button {
  background: none;
}
