.modal_blur {
    background-color: rgba($color: #000000, $alpha: 0.5);
    backdrop-filter: blur(10px);
}

.modal_container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 300;

    .container_inner {
        padding: 30px;

        width: 360px;
        height: 370px;
        box-sizing: border-box;
        position: relative;
        background: #ffffff;
        box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
        border-radius: 20px;

        .modal_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: max-content;

            p {
                font-size: 24px;
                font-weight: 700;
            }

            button {
                background: none;
                width: 40px;
                height: 40px;
                font-size: 20px;
                border-radius: 100%;
            }

            button:hover {
                background-color: #cccccc;
            }
        }

        .modal_desc {
            margin-top: 50px;
            font-size: 16px;
            font-weight: 700;
        }

        .modal_btn_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: auto;
            position: absolute;
            width: 300px;
            bottom: 35px;

            button {
                width: 140px;
                height: 40px;

                a {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
            }

            button.btn_one {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .container_inner.alarm {
        width: 360px;
        height: 370px;
    }

    .container_inner.template {
        width: 1155px;
        height: 630px;

        .template_category {
            display: flex;

            p {
                width: 120px;
                font-size: 24px;
                font-weight: 700;
                text-align: center;
                cursor: pointer;
            }

            p.active {
                color: #1e69cb;
                border-bottom: 3px solid #1e69cb;
            }
        }

        .template_list {
            width: 100%;
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
            justify-content: center;
            align-items: center;
            justify-items: center;
            .template_item {
                width: 345px;
                height: 207px;
                border-radius: 15px;
                overflow: hidden;
                position: relative;

                box-sizing: border-box;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .template_name {
                    font-size: 24px;
                    font-weight: 700;
                    color: white;
                    position: absolute;
                    left: 10px;
                    bottom: 10px;
                    z-index: 3;
                }

                .sel_btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 120px;
                    height: 40px;
                    z-index: 3;
                }
            }

            .template_item:hover::after {
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.5);
            }
        }

        .template_list::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .container_inner.profile {
        width: 360px;
        height: 600px;

        .modal_icon_box {
            position: relative;
            width: 150px;
            margin: 62px auto 0 auto;

            .icon_box {
                width: 150px;
                height: 150px;
                margin: 0 auto;
                border-radius: 50%;
                box-sizing: border-box;

                figure {
                    width: 150px;
                    height: 150px;
                    overflow: hidden;
                    border: 2px solid white;
                    border-radius: 50%;
                    box-sizing: border-box;
                    position: relative;
                    z-index: 2;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        box-sizing: border-box;
                    }
                }

                .icon.att {
                    position: absolute;
                    left: 50%;
                    top: 110px;
                    margin-left: 35px;
                    background-position-x: 0;
                    cursor: pointer;
                    z-index: 3;
                }

                .icon {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: url(../assets/ui/edit_theme_bl.png) no-repeat 0
                        0 / auto 101%;
                    vertical-align: top;
                }

                .logo_text {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: white;
                    font-size: 20px;
                    font-weight: 700;
                    text-align: center;
                    z-index: 1;
                }
            }

            .icon_box:hover {
                cursor: pointer;
            }

            .icon_box:hover .icon.att {
                background-position-x: 100%;
            }
        }

        .modal_box_text {
            margin-top: 10px;

            .modal_title {
                font-size: 16px;
                font-weight: 700;
            }

            .modal_text {
                font-size: 14px;
                color: #cccccc;
                margin-top: 6px;
                margin-left: 18px;
            }
        }

        .modal_btn_wrapper {
            .modal_del_btn {
                width: 47px;
                height: 36px;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
}

.modal_container.alert {
    .modal_header {
        p {
            color: #ee3030;
        }
    }
}

@media (max-width: 1440px) {
    .modal_container {
        .container_inner {
            width: 100%;
            height: max-content;
        }

        .container_inner.template {
            width: 100%;
            height: max-content;
        }
    }
}

@media (max-width: 1120px) {
    .modal_container {
        .container_inner.template {
            .template_list {
                grid-template-columns: repeat(2, 1fr);
                .template_item {
                    width: 100%;
                    height: 210px;
                }
            }
        }
    }
}

@media (max-width: 760px) {
    .modal_container {
        .container_inner {
            height: 100%;
        }
        .container_inner.template {
            height: 100%;

            .template_list {
                height: 100%;
                grid-template-columns: repeat(1, 1fr);
                overflow-y: auto;

                &::-webkit-scrollbar {
                    display: none;
                }

                .template_item {
                    width: 100%;
                    height: 230px;
                }
            }
        }
    }
}
