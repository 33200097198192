.list_container {
    border-top: 1px solid #cccccc;
    padding-bottom: 120px;
    min-height: 1000px;
    padding-top: 90px;

    .container_inner {
        width: 1460px;

        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;

        .list_header {
            margin-top: 82px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 32px;
                font-weight: 700;
            }

            .dropdown_btn {
                width: 180px;
                height: 40px;
                z-index: 50;

                button {
                    display: flex;

                    align-items: center;
                    padding-left: 20px;
                }
            }
        }

        .list_item {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            justify-items: center;
            align-items: center;
            margin-bottom: 60px;

            .list_item_box {
                width: 100%;

                display: flex;
                align-items: center;
                justify-content: space-between;
                justify-items: center;
                align-content: space-between;
            }
        }
    }
}

@media (max-width: 1440px) {
    .list_container {
        .container_inner {
            width: 100%;
            padding: 0 10px;

            .list_item {
                justify-content: center;
            }
        }
    }
}

@media (max-width: 1024px) {
    .list_container {
        .container_inner {
            .list_item {
                grid-template-columns: repeat(3, 1fr);
                gap: 2px;
            }
        }
    }
}

@media (max-width: 768px) {
    .list_container {
        .container_inner {
            .list_item {
                grid-template-columns: repeat(2, 1fr);
                gap: 2px;
            }
        }
    }
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.main_section {
    .container_inner {
        margin-left: auto;
        margin-right: auto;
        width: 1440px;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 200px;
    }
}

.section_intro {
    position: relative;
    height: calc(100vh - 90px);
    width: 100%;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(70%);
    }

    .main_text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 60px;
        text-align: center;
        color: white;
        width: 100%;

        img {
            width: 486px;
        }

        p {
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
        }

        .text_wrap {
            display: flex;
            justify-self: center;
            align-items: center;
            gap: 22px;
            font-size: 48px;
            font-weight: 500;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
            text-align: center;

            span {
                color: #fee500;
            }
        }

        .button_wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;

            p {
                font-size: 16px;
                font-weight: 500;
            }
        }

        button.main_btn {
            width: 300px;
            height: 90px;
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            line-height: 1.5;
            border-radius: 45px;
            a {
                display: flex;
                flex-direction: column;

                span {
                    font-size: 24px;
                    font-weight: 700;
                }
            }
        }
    }
}

.section_list {
    margin-top: 64px;

    .container_inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 60px;
    }

    .main_list {
        height: max-content;
        width: 1460px;

        position: relative;

        .main_list_title {
            font-size: 24px;
            font-weight: 700;
        }

        .main_list_container {
            position: relative;
            margin-top: 20px;

            .main_list_ul {
                width: 100%;
                white-space: nowrap;
            }
        }
    }
}

.section_detail {
    margin-top: 200px;
    padding-bottom: 270px;

    .container_inner {
        .box_wrapper {
            display: flex;

            height: max-content;

            video {
                width: 50%;
            }

            .box_item {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .item_text_main {
                    font-size: 48px;
                    font-weight: 700;

                    .item_text_underline {
                        background: linear-gradient(
                            180deg,
                            rgba(255, 255, 255, 0) 50%,
                            #fee500 50%
                        );
                    }

                    .item_text_theme {
                        color: #1e69cb;
                    }
                }

                .item_text_sub {
                    margin-top: 36px;
                    font-size: 24px;
                    font-weight: 400;

                    .item_text_through {
                        text-decoration-line: line-through;
                    }
                }

                .item_btn {
                    margin-top: 94px;
                    width: 300px;
                    height: 60px;
                    font-size: 20px;
                }
            }

            .box_item:nth-child(2) {
                padding-left: 60px;
            }
        }
    }
}

.tst {
    width: 500px;
    height: 500px;
    background-color: red;
    border: 2px solid blue;
    position: absolute;
}

@media (max-width: 1440px) {
    .section_intro {
        .main_text {
            img {
                width: 400px;
            }

            .text_wrap {
                font-size: 40px;
                gap: 12px;
            }
        }
    }

    .section_list {
        padding: 0 10px;
        width: 100%;
        .container_inner {
            width: 100%;
            .main_list {
                padding: 0 20px;
                width: 100%;
                .main_list_container {
                    width: 100%;
                }
            }
        }
    }

    .main_section {
        .container_inner {
            width: 100%;

            .box_wrapper {
                .box_item {
                    padding-left: 20px;
                    .item_text_main {
                        font-size: 32px;
                    }
                    .item_text_sub {
                        font-size: 20px;
                        padding-right: 20px;
                    }
                }
                .box_item:nth-child(2) {
                    padding-left: 30px;
                }
            }
        }
    }
}

@media (max-width: 1210px) {
    .section_intro {
        .main_text {
            img {
                width: 360px;
            }

            .text_wrap {
                font-size: 32px;
                gap: 12px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .section_intro {
        .main_text {
            img {
                width: 300px;
            }

            .text_wrap {
                font-size: 28px;
                gap: 8px;
            }

            .button_wrap {
                p {
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }

    .list_container {
        .section_list {
            padding: 0;
            .container_inner {
                padding: 0;
                .main_list {
                    padding: 0;
                }
            }
        }
    }
}

@media (max-width: 765px) {
    .main_section {
        .container_inner {
            .box_wrapper {
                .box_item {
                    .item_text_main {
                        font-size: 22px;
                    }
                    .item_text_sub {
                        font-size: 14px;
                    }
                    .item_btn {
                        margin-top: 30px;
                        width: 180px;
                        height: 46px;
                        font-size: 14px;
                    }
                }
                .box_item:nth-child(2) {
                    padding-left: 20px;
                }
            }
        }
    }
}

@media (max-width: 460px) {
    .main_section {
        .container_inner {
            .box_wrapper {
                .box_item {
                    .item_text_main {
                        font-size: 18px;
                    }
                    .item_text_sub {
                        font-size: 10px;
                    }
                    .item_btn {
                        width: 160px;
                        height: 40px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
