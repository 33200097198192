.media-container {
    top: 10px;
    left: 10px;
    width: max-content;
    height: calc(100% - 270px);
    position: absolute;
    overflow: scroll;

    justify-content: center;

    #layoutContainerWrapper {
        display: none;
    }

    #roomContainer {
        display: none;
    }

    .playerContainer {
        // overflow-y: scroll;
        width: 100%;
        height: 100%;
        padding: 3px;

        &::-webkit-scrollbar {
            display: none;
        }

        .playerBox.focused {
            outline: 3px solid #fee500;
        }

        .playerBox {
            border-radius: 15px;
            position: relative;
            margin-bottom: 10px;

            width: 190px;
            min-height: 33px;
            height: max-content;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            z-index: 99;

            &:last-child {
                margin-bottom: 0;
            }

            // 사용자 닉네임
            div[class^='bottomRightContainer'] {
                display: none;
            }

            button {
                display: none;
            }

            .localFaceCam {
                width: 190px;
                height: 120px;

                display: none;
                &.active {
                    display: block;
                    object-fit: cover;

                    border-radius: 15px;
                    border: 3px solid transparent;
                    &:hover {
                        border: 3px solid #fee500;
                    }
                }

                &.focused {
                    border: 3px solid #fee500;
                }
            }

            .MP_subscriber_overlay {
                border-radius: 15px;
                border: 3px solid transparent;

                &:hover {
                    border: 3px solid #fee500;
                }

                &.focused {
                    border: 3px solid #fee500;
                }
            }

            // .MP_screenshare_subscriber {
            //     border: 3px solid transparent;

            //     &:hover {
            //         border: 3px solid #fee500;
            //     }
            // }

            .playerInfoBox {
                display: flex;
                justify-content: space-between;
                position: absolute;
                left: 3px;
                bottom: 3px;
                z-index: 99;
                width: 184px;
                height: 30px;

                .playerInfo {
                    box-sizing: border-box;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 30px;
                    width: 150px;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: cover;
                        border-radius: 50%;
                        box-sizing: border-box;
                    }

                    img.active {
                        border: 3px solid #fee500;
                    }

                    span {
                        color: white;
                        font-size: 12px;
                        font-weight: 700;
                        margin-left: 5px;
                    }
                }
            }

            .micStatus.disabled {
                background-position-x: 100%;
            }

            .micStatus {
                width: 30px;
                height: 30px;
                background: url(../assets/ui/media_mic_icon_pack.png) no-repeat
                    0 0 / auto 100%;
                z-index: 5;
            }

            .OT_publisher {
                width: 190px !important;
                min-width: 190px;
                max-height: 120px;

                border-radius: 15px;
            }

            .OT_subscriber {
                width: 190px !important;
                min-height: 120px;
                max-height: 120px;
                border-radius: 15px;
                position: relative !important;
            }

            .OT_widget-container {
                background: transparent;
                .OT_video-element {
                    object-fit: cover;
                    border-radius: 15px;
                    border: 3px solid transparent;
                    &:hover {
                        border: 3px solid #fee500;
                    }
                    &.focused {
                        border: 3px solid #fee500;
                    }
                }
            }

            .localFaceCam.active + .OT_screenshare .OT_widget-container {
                background: none;
            }
        }
    }
}

.media-container::-webkit-scrollbar {
    display: none;
}

.video_container {
}

#roomContainer {
    display: flex;

    #layoutContainerWrapper {
        width: 200px;
        height: 500px;
    }
}

.focusScreen.enabled {
    display: block;
}

.focusScreen.enabled.fullscreen {
    width: calc(100% - 20px);

    height: calc(100% - 90px);
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 16px;
    z-index: 110;

    .playerInfoBox {
        top: 20px;
        left: 20px;
    }

    .btnBox {
        top: 20px;
        right: 20px;
    }

    .video {
        width: 100%;
        height: 100%;
    }
}

.focusScreen.enabled.exceptChat {
    width: calc(100% - 250px - 360px);
}

.focusScreen.enabled.fullscreen.exceptChat {
    width: calc(100% - 360px);
}

.focusScreen {
    display: none;
    position: absolute;
    top: 10px;
    left: 220px;
    width: calc(100% - 230px);
    height: calc(100% - 90px);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    z-index: 999;

    .playerInfoBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 255px;
        height: 40px;
        z-index: 5;

        .playerInfo {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 30px;
            width: 200px;
            height: 40px;
            display: flex;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 50%;
            }

            span {
                color: white;
                font-size: 12px;
                font-weight: 700;
                margin-left: 6px;
            }
        }

        .micStatus.disabled {
            background-position-x: 100%;
        }

        .micStatus {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 40px;
            height: 40px;
            background: url(../assets//ui/media_mic_icon_pack.png) no-repeat 0 0 /
                auto 100%;
            z-index: 5;
        }
    }

    .btnBox {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;

        .smallscreenBtn {
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: url(../assets//ui/media_smallscreen_icon_pack.png)
                no-repeat 0 0 / auto 100%;
        }

        .smallscreenBtn:hover {
            background-position-x: 100%;
        }

        .fullscreenBtn {
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: url(../assets//ui/media_fullscreen_icon_pack.png)
                no-repeat 0 0 / auto 100%;
        }

        .fullscreenBtn:hover {
            background-position-x: 100%;
        }

        .closeBtn {
            cursor: pointer;
            margin-left: 15px;
            width: 40px;
            height: 40px;
            background: url(../assets//ui/media_close_icon_pack.png) no-repeat 0
                0 / auto 100%;
        }

        .closeBtn:hover {
            background-position-x: 100%;
        }
    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 1320px) {
    .media-container {
        height: calc(100% - 300px);
    }
}

@media (max-width: 1024px) {
    .media-container {
        overflow: scroll;
        position: relative;
        top: 80px;
        left: 10px;
        width: calc(100% - 10px);
        min-height: 36px;
        max-height: 126px;
        height: auto;

        justify-content: flex-start;

        &::-webkit-scrollbar {
            display: none;
        }

        .playerContainer {
            padding-top: 3px;
            position: relative;
            width: max-content;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 10px;

            margin: 0;

            .playerBox {
                height: max-content;
                margin: 0;
                flex-direction: row;
                width: auto;
                min-width: 190px;
            }
        }
    }

    .focusScreen {
        top: 336px;

        width: 100%;
        height: calc(100% - 420px);
        left: 0;
    }

    .focusScreen.enabled.fullscreen {
        top: 80px;
        height: calc(100% - 160px);
    }
}

@media (max-width: 768px) {
    .media-container {
        top: 60px;
    }

    .focusScreen {
        top: 316px;
        height: calc(100% - 380px);
    }

    .focusScreen.enabled.fullscreen {
        top: 60px;
        height: calc(100% - 120px);
    }
}
