.land_create_container {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding-top: 45px;
  padding-bottom: 270px;

  .container_inner {
    width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .header {
      p {
        font-size: 24px;
        font-weight: 900;
      }
    }

    .main {
      margin-top: 40px;

      form {
        display: flex;

        .box_thumb {
          width: calc(100% - 450px);
          height: 540px;
          position: relative;
          background-color: gray;

          .thumb_bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .box_logo {
            position: absolute;
            top: 20px;
            left: 20px;
            box-sizing: border-box;

            .logo {
              width: 150px;
              height: 150px;
              margin: 0 auto;
              border-radius: 50%;
              box-sizing: border-box;

              figure {
                width: 150px;
                height: 150px;
                overflow: hidden;
                border: 2px solid white;
                border-radius: 50%;
                box-sizing: border-box;
                position: relative;
                z-index: 2;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  box-sizing: border-box;
                }
              }

              .icon.att {
                position: absolute;
                left: 50%;
                top: 110px;
                margin-left: 35px;
                background-position-x: 0;
                cursor: pointer;
                z-index: 3;
              }

              .icon {
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: url(../assets/ui/edit_theme_bl.png) no-repeat 0 0 / auto 101%;
                vertical-align: top;
              }

              .logo_text {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 20px;
                font-weight: 700;
                text-align: center;
                z-index: 1;
              }
            }

            .logo:hover {
              cursor: pointer;
            }

            .logo:hover .icon.att {
              background-position-x: 100%;
            }

            .delete {
              display: inline-block;
              position: absolute;
              left: calc(100% + 5px);
              top: 110px;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-position-x: 0;
              background: url(../assets/ui/delete_icon_pack.png) no-repeat 0 0 / auto 101%;
              vertical-align: top;
            }

            .delete:hover {
              background-position-x: 100%;
            }
          }

          .sel_btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 40px;
          }

          .text_wrapper {
            position: absolute;
            left: 20px;
            bottom: 20px;
            color: white;

            .text_team {
              font-size: 32px;
              font-weight: 700;
            }

            .profile_box {
              display: flex;
              align-items: center;

              figure {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                border: 2px solid white;
                box-sizing: border-box;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }

              span {
                font-size: 20px;
                font-weight: 700;
                margin-left: 13px;
              }
            }
          }
        }

        .input_wrapper {
          width: 450px;
          margin-left: 45px;

          .input_item {
            input {
              height: 40px;
            }
          }

          .input_item:not(:first-child) {
            margin-top: 20px;
          }

          .submit_btn {
            margin-top: 40px;
            text-align: end;

            button {
              width: 120px;
              height: 40px;
              text-align: center;
            }

            button:first-child {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .land_create_container {
    .container_inner {
      width: 100%;
      padding: 0 10px;
    }
  }
}

@media (max-width: 1024px) {
  .land_create_container {
    .container_inner {
      .main {
        form {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 40px;

          .box_thumb {
            width: 100%;
          }

          .input_wrapper {
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .input_item {
              width: 100%;
            }

            .submit_btn {
              button {
                width: 200px;
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .land_create_container {
    .container_inner {
      .main {
        form {
          .box_thumb {
            height: 400px;
            .box_logo {
              .logo {
                figure {
                  width: 120px;
                  height: 120px;
                }
                .logo_text {
                  font-size: 16px;
                }
                .icon.att {
                  left: 48px;
                  top: 90px;
                }
                .icon {
                  width: 30px;
                  height: 30px;
                }
              }

              .delete {
                top: 90px;
                width: 30px;
                height: 30px;
                left: 116px;
              }
            }
          }
          .input_wrapper {
            .submit_btn {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 40px;
              button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
