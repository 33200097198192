:root {
  --vh: 1080;
  --inner-scale: 1;
  --vw: 1920;
}

body * {
}

body {
  margin: 0;
  padding: 0;
}

a,
button {
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-size: inherit;
  color: inherit;
}

// .hidden {
//   visibility: hidden;
// }

.visible {
  visibility: visible;
}

.blind {
  position: absolute;
  width: 1px;
  height: 1px;
  text-indent: -999em;
  overflow: hidden;
}

.confirm-box {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  padding: 14px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  font-size: 21px;
  color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.5s;
}

.text-box {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  padding: 2px 30px 2px 30px;
  color: white;
}

.btn:hover {
  background: rgb(255, 204, 0);
  color: rgb(0, 0, 0);
}

.btn-round {
  background-color: #000000;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  width: 120px;
  height: 40px;

  span {
    color: white;
    font-size: 18px;
  }
}

.btn-round:hover {
  background: rgb(255, 204, 0);

  span {
    color: rgb(0, 0, 0);
  }
}

.btn-stroke {
  background-color: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  width: 120px;
  height: 40px;

  span {
    font-size: 18px;
  }
}

.btn-stroke:hover {
  background-color: rgb(255, 204, 0);
}

#webview-close-btn {
  background: url(../assets/ui/close-small.svg) 50% center / 72% no-repeat rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.webview-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .webview-outer-size {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: transparent !important;
  }

  .webview-container-outer-lockscreen {
    width: calc((var(--vh)) * 540 * 1px / 1080) !important;
    height: calc((var(--vh)) * 330 * 1px / 1080) !important;
  }

  .webview-container-outer-profile {
    width: calc((var(--vh)) * 517 * 1px / 1080) !important;
    height: calc((var(--vh)) * 770 * 1px / 1080) !important;
  }

  .webview-container-outer-fishresult {
    width: calc((var(--vh)) * 270 * 1px / 1080) !important;
    height: calc((var(--vh)) * 760 * 1px / 1080) !important;
  }

  .webview-container-outer-edit {
    width: calc((var(--vh) * 1200 * 1px) / 1080) !important;
    height: calc((var(--vh) * 693 * 1px) / 1080) !important;
  }

  .webview-container-outer-show {
    width: calc((var(--vh) * 1200 * 1px) / 1080) !important;
    height: calc((var(--vh) * 900 * 1px) / 1080) !important;
  }

  @media screen and (max-width: 768px) {
    .webview-container-outer-show {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .webview-container-outer-rankinglist {
    width: calc((var(--vh) * 1200 * 1px) / 1080) !important;
    height: calc((var(--vh) * 770 * 1px) / 1080) !important;
  }

  @media screen and (max-width: 768px) {
    .webview-container-outer-rankinglist {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .webview-container-inner-lockscreen {
    width: calc((1080 * 540 * 1px) / 1080);
    height: calc((1080 * 330 * 1px) / 1080);
    transform: scale((var(--inner-scale)));
    transform-origin: 0px 0px;
  }

  .webview-container-inner-profile {
    width: calc((1080 * 517 * 1px) / 1080);
    height: calc((1080 * 770 * 1px) / 1080);
    transform: scale((var(--inner-scale)));
    transform-origin: 0px 0px;
  }

  .webview-container-inner-edit {
    width: calc((1080 * 1200 * 1px) / 1080);
    height: calc((1080 * 693 * 1px) / 1080);
    transform: scale((var(--inner-scale)));
    transform-origin: 0px 0px;
  }

  .webview-container-inner-show {
    width: calc((1080 * 1200 * 1px) / 1080);
    height: calc((1080 * 900 * 1px) / 1080);
    transform: scale((var(--inner-scale)));
    transform-origin: 0px 0px;
  }

  @media screen and (max-width: 768px) {
    .webview-container-inner-show {
      width: 100% !important;
      height: 100% !important;
      padding: 10px;
      box-sizing: border-box;

      .webview-body {
        height: calc(100% - 50px);

        .body-text {
          height: calc(100% - 250px);
          overflow: hidden;

          p {
            margin-top: 5px;
            height: calc(100% - 30px);
            overflow: scroll;
          }
        }
      }
    }
  }

  .webview-container-inner-chart-show {
    width: calc((1080 * 1200 * 1px) / 1080);
    height: calc((1080 * 900 * 1px) / 1080);
    transform: scale((var(--inner-scale)));
    transform-origin: 0px 0px;

    .chart-container {
      width: 100%;
      height: 610px;
      background-color: white;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
    }

    .webview-footer {
      display: flex;
      margin-top: 23px;

      button {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 20px;
        padding: 2px 30px 2px 30px;
        color: white;
        margin-right: 20px;
      }
    }
  }

  .webview-container-inner-fishresult {
    width: calc((1080 * 270 * 1px) / 1080);
    height: calc((1080 * 760 * 1px) / 1080);
    transform: scale((var(--inner-scale)));
    transform-origin: 0px 0px;

    .btn-wrap-fish {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      display: flex;
      justify-content: center;

      .btn-round {
        margin-right: 20px;
      }
    }

    .inp-input {
      margin-top: 10px;
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 20px;
      width: 270px;
      height: 40px;
      text-align: center;
      color: white;
      font-size: 21px;

      span {
        color: white;
        font-size: 18px;
      }
    }

    .fish-service-text {
      input {
        margin-top: 29px;
        margin-bottom: 20px;
      }

      div {
        width: 248px;
        margin: 0 auto 0 auto;
        color: white;
        text-align: center;
        letter-spacing: -0.015em;
        box-sizing: border-box;
        font-size: 10px;
      }
    }
  }

  .webview-container-inner-rankinglist {
    width: calc((1080 * 1200 * 1px) / 1080);
    height: calc((1080 * 770 * 1px) / 1080);
    transform: scale((var(--inner-scale)));
    transform-origin: 0px 0px;

    .webview-body {
      background-color: #f5f5f5;
      border-radius: 20px;
      overflow: hidden;
      height: 705px;

      .webview-body-inner {
        box-sizing: border-box;
        padding: 30px;
        height: 100%;

        .webview-mobile {
          display: none;
        }

        .content-container-fish-items {
          width: 100%;
          overflow: auto;
          background: #ffffff;
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          padding: 20px;

          .fish-items-column-header {
            display: flex;
            text-align: center;
            box-sizing: border-box;

            div {
              box-sizing: border-box;
              width: 25%;
              line-height: 66px;
            }

            .fish-items-tier {
              display: flex;
              align-items: center;
              box-sizing: border-box;

              figure {
                width: 82px;
                height: 66px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }

          .fish-items-column {
            display: flex;
            text-align: center;
            box-sizing: border-box;

            div {
              box-sizing: border-box;
              width: 25%;
              line-height: 66px;
            }

            .fish-items-tier {
              display: flex;
              align-items: center;
              box-sizing: border-box;

              span {
                flex-grow: 1;
              }

              figure {
                width: 82px;
                height: 66px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  box-sizing: border-box;
                }
              }
            }
          }

          .fish-items-column:not(:first-child) {
            margin: 20px 0 20px 0;
          }
        }

        .content-container-rankinglist {
          width: 100%;
          overflow: auto;
          background: #ffffff;
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          padding: 20px;

          .rank-list-item:first-child {
            margin-top: 30px;
          }

          .rank-list-item {
            display: flex;
            height: 66px;
            align-items: center;
            margin-bottom: 30px;

            .my-ranking {
              width: 80px;
              height: 100%;
              display: flex;
              align-items: center;
              margin-right: 5px;

              img {
                width: 100%;
              }
            }

            .medal {
              width: 24px;
              height: 24px;
              margin-right: 20px;
            }

            .ranking-text {
              width: 120px;
              font-size: 36px;
              font-weight: 700;
            }

            .phone-text {
              width: 150px;
              font-size: 18px;
              font-weight: 700;
            }

            .tier-text {
              width: 180px;
              font-size: 18px;
              font-weight: 700;
            }

            .name-text {
              width: 200px;
              font-size: 36px;
              font-weight: 700;
            }

            .size-text {
              width: 150px;
              font-size: 36px;
              font-weight: 700;

              span {
                font-size: 18px;
              }
            }

            .item-img {
              width: 82px;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .webview-container-inner-rankinglist {
      width: 100% !important;
      height: 100% !important;
      padding: 10px 5px 10px 5px;
      box-sizing: border-box;

      .webview-body {
        height: calc(100% - 70px);

        .webview-body-inner {
          padding: 10px;

          .content-container-rankinglist {
            padding: 0;

            .rank-list-item {
              .my-ranking {
                width: 10%;
                margin-right: 2px;
              }

              .medal {
                margin-right: 0;
                width: 15px;
                height: 15px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .ranking-text {
                font-size: 16px;
                width: 7%;
                margin-right: 5px;
              }

              .phone-text {
                font-size: 10px;
                width: 22%;
                margin-right: 6px;
              }

              .webview-mobile {
                width: 26%;
              }

              .tier-text {
                font-size: 8px;
                width: auto;
              }

              .name-text {
                font-size: 14px;
                width: auto;
              }

              .size-text {
                font-size: 14px;
                width: 14%;

                span {
                  font-size: 14px;
                }
              }

              .item-img {
                width: 46px;
                height: 40px;

                img {
                  box-sizing: border-box;
                }
              }
            }
          }

          .webview-mobile {
            display: block !important;
          }
          .webview-pc {
            display: none;
          }

          .content-container-fish-items {
            padding: 5px;

            .fish-items-column {
              .item-img {
                width: 48px !important;
                min-width: 48px;
                height: 40px !important;
              }

              div:first-child {
                width: 45% !important;
                font-size: 12px;
              }

              div:nth-child(2) {
                width: 25% !important;
                font-size: 12px;
              }

              div:nth-child(3) {
                width: 30% !important;
                font-size: 12px;
              }

              .fish-items-tier {
                div {
                  width: auto !important;
                  min-width: 82px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  margin-left: 5px;

                  span {
                    line-height: 30px;
                  }

                  span:first-child {
                    font-size: 10px;
                  }

                  span:nth-child(2) {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .webview-container-inner-lockscreen {
    .webview-body-lockscreen {
      background-color: #f5f5f5;
      width: 100%;
      height: calc(100% - 64px);
      border-radius: 20px;

      .webview-body-lockscreen-inner {
        height: 100%;

        .lockscreen-container {
          height: 100%;

          p {
            font-size: 24px;
            padding-top: 40px;
            box-sizing: border-box;
            text-align: center;
          }

          .lockscreen-inp-wrap {
            display: flex;
            width: 330px;
            height: 60px;
            margin: 53px auto 0 auto;
            justify-content: space-between;

            input {
              width: 60px;
              height: 60px;
              text-align: center;
              border-radius: 20px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .webview-container-inner-profile {
    .webview-body-profile {
      background-color: #f5f5f5;
      border-radius: 20px;
      overflow: hidden;
      box-sizing: border-box;
      padding: 30px;

      .webview-body-profile-inner {
        .profile-container {
          display: flex;
          flex-direction: column;
          padding: 0 110px 0 50px;
          margin: 60px 0 20px 0;

          .inp-wrap {
            display: flex;
            position: relative;
            margin-top: 20px;
            font-size: 14px;
            width: 100%;

            .inp-title {
              min-width: 50px;
              margin-right: 20px;
              text-align: right;
              font-size: 18px;
            }

            .photo-box {
              position: relative;
              width: 100%;
              margin-bottom: 12px;

              figure {
                overflow: hidden;
                width: 150px;
                height: 150px;
                margin: 0 auto;
                border: 1px solid #000;
                border-radius: 50%;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .icon.att {
                position: absolute;
                left: 50%;
                top: 110px;
                margin-left: 35px;
                background-position-x: 0;
                cursor: pointer;
              }

              .icon.att:hover {
                background-position-x: 33.33%;
              }

              .icon {
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: url(../assets/ui/icon_round-pack.png) no-repeat 0 0 / auto 101%;
                vertical-align: top;
              }
            }

            .inp-input {
              box-sizing: border-box;
              width: 100%;
              height: 40px;
              padding: 9px 18px;
              background-color: #fff;
              border: 1px solid #000;
              border-radius: 20px;
              font-size: 14px;
            }
          }

          .desc {
            margin-top: 15px;
            font-size: 12px;
            text-align: center;
          }

          .btn-wrap {
            margin-top: 170px;
            padding-left: 70px;

            .btn-round {
              color: white;
            }

            .btn-round:hover {
              color: black;
            }

            .btn {
              display: block;
              width: 100%;
              height: 40px;
              border-radius: 20px;
              font-size: 18px;
              line-height: 38px;
              text-align: center;
            }

            .btn + .btn {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  .webview-header {
    position: relative;
    margin-bottom: 20px;

    #webview-title {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      height: 40px;
      color: white;
      font-size: 24px;
      line-height: 38px;
      font-weight: 700;
    }

    #webview-close-btn {
      position: absolute;
      top: 0;
      right: 0;
    }

    #webview-close-btn:hover {
      background-color: rgb(255, 204, 0);
      background-image: url(../assets/ui/close-small_bk.svg);
    }
  }

  .webview-body {
    .webview-body-inner {
      display: flex;

      .body-section-1 {
        width: 50%;
        box-sizing: border-box;
        overflow: hidden;

        .content-box {
          height: 100%;
          max-height: 400px;

          .content {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .content-detail-box {
          margin: 12px 0px 10px;

          .text-box {
            display: inline-block;
            height: 40px;
            font-size: 24px;
            font-weight: 700;
          }
        }

        p {
          height: 160px;
          overflow: auto;
        }
      }

      .body-section-2 {
        width: 50%;
        box-sizing: border-box;
        padding-left: 20px;

        .inp-wrap:first-child {
          margin-top: 0;
        }

        .inp-wrap {
          margin-top: 20px;
          width: 100%;

          .inp-title {
            margin-bottom: 2px;
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            color: #ffffff;
            min-width: 50px;
            display: block;
            margin-right: 20px;
          }

          .inp-input {
            border-color: #ffffff;
            background: rgba(255, 255, 255, 0.2);
            font-size: 21px;
            color: rgb(255, 255, 255);
            box-sizing: border-box;
            width: 100%;
            height: 40px;
            padding: 9px 18px;
            border: 1px solid;
            border-radius: 20px;
          }

          .inp-textarea {
            width: 100%;
            height: 150px;
            padding: 10px 20px;
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid rgb(255, 255, 255);
            border-radius: 20px;
            font-size: 21px;
            color: rgb(255, 255, 255);
            box-sizing: border-box;
            resize: none;
          }

          .inp-input-file {
            display: block;
            line-height: 20px;
          }

          .inp-inner {
            display: flex;
            align-items: center;

            .inp-input {
              width: auto;
              flex-grow: 1;
            }

            span {
              margin-right: 15px;
            }
          }
        }
      }

      .btn-wrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        button:not(:first-child) {
          margin-left: 20px;
        }
      }

      .content-container-fishresult {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        figure {
          width: 100%;
          height: auto;

          img {
            height: 100%;
            object-fit: cover;
            box-sizing: border-box;
          }
        }

        .fish-tier {
          margin: 10px 0 10px 0;
          font-size: 24px;
        }

        .fish-name {
          font-size: 48px;
          font-weight: 700;
          color: white;
        }

        .fish-size {
          font-size: 96px;
          font-weight: 900;
          color: white;
          line-height: 90px;
          word-break: break-all;

          .fish-size-unit {
            font-size: 40px;
          }
        }

        .fish-info {
          font-size: 18px;
          color: white;
        }
      }
    }

    .content-container {
      margin-bottom: 10px;

      figure {
        width: 100%;
        height: 675px;
      }

      .content {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    @media screen and (max-width: 768px) {
      .content-container {
        figure {
          height: 200px;
        }
      }
    }

    .body-text {
      p {
        color: white;
      }
    }
  }

  .webview-youtube-body {
    height: 100%;

    .webview-youtube-body-inner {
      height: 100%;

      .body-section-1 {
        display: flex;
        align-items: center;
      }

      .body-section-2 {
        display: flex;
        align-items: center;

        form {
          width: 100%;
        }
      }

      .btn-wrap {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

.border-legend {
  border: 3px solid;
  border-image: linear-gradient(to right, #ffaced 0%, #acffb9 49.48%, #29a5ff 100%);
  border-image-slice: 1;
}

.border-grandmaster {
  border: 3px solid;
  border-image: linear-gradient(to right, #80ff94 0%, #50b5ff 100%);
  border-image-slice: 1;
}

.border-master {
  border: 3px solid #80ff94;
}

.border-diamond {
  border: 3px solid #50b5ff;
}

.border-platinum {
  border: 3px solid #ffaced;
}

.border-gold {
  border: 3px solid #ffb800;
}

.border-silver {
  border: 3px solid #c4c4c4;
}

.border-bronze {
  border: 3px solid #a97a5f;
}

.border-iron {
  border: 3px solid #808080;
}

.tier-text-legend {
  background: linear-gradient(to right, #ffaced 0%, #80ff94 49.48%, #50b5ff 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.tier-text-grandmaster {
  background: linear-gradient(90deg, #80ff94 0%, #50b5ff 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.tier-text-master {
  color: #80ff94;
}

.tier-text-diamond {
  color: rgba(80, 181, 255, 1);
}

.tier-text-platinum {
  color: rgba(255, 172, 237, 1);
}

.tier-text-gold {
  color: rgba(255, 184, 0, 1);
}

.tier-text-silver {
  color: rgba(196, 196, 196, 1);
}

.tier-text-bronze {
  color: rgba(169, 122, 95, 1);
}

.tier-text-iron {
  color: rgba(128, 128, 128, 1);
}
