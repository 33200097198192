.login_container {
    display: flex;
    width: 100%;
    height: 100vh;

    .login_bg_box {
        width: calc(100% - 560px);
        height: 100%;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

    .menu_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 560px;

        .menu_box_inner {
            width: 460px;
        }
    }

    .login_menu_box {
        .login_menu_box_inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .logo_box {
                text-align: center;

                img {
                }
            }

            .text_box {
                margin-top: 100px;

                text-align: center;

                .text__1 {
                    font-size: 40px;
                    font-weight: 700;

                    span {
                        color: #1e69cb;
                    }
                }

                .text__2 {
                    margin-top: 36px;
                    font-size: 20px;
                }
            }

            .login_btn_box {
                margin-top: 100px;
                display: flex;
                flex-direction: column;

                a {
                    width: 300px;
                    height: 40px;
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 20px;

                    img {
                        position: absolute;
                        border-radius: 20px;
                    }

                    span {
                        display: block;
                        width: 100%;
                        text-align: center;
                        line-height: 40px;
                        font-size: 16px;
                        font-weight: 700;
                    }
                }

                .kakao_btn {
                    background-color: #fee500;
                }

                .google_btn {
                    position: relative;
                    border: 0.2px solid #000000;
                    box-sizing: border-box;
                    margin-top: 10px;

                    img {
                        top: 50%;
                        left: 7px;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    .box_profile {
        width: 300px;
        margin-left: auto;
        margin-right: auto;

        .profile_wrapper {
            .profile_header {
                text-align: center;
                font-size: 24px;
                font-weight: 700;
            }

            .photo_box {
                margin-top: 62px;
            }

            .input_box_wrapper {
                margin-top: 46px;

                p {
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-weight: 700;
                }

                .input_box {
                    width: 100%;
                    height: 40px;
                }
            }

            .term_box {
                margin-top: 35px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .term_check_item {
                    display: flex;
                    gap: 4px;

                    input {
                        width: 18px;
                        height: 18px;
                        border: inherit;
                        -webkit-appearance: auto;
                    }

                    p {
                        margin-left: 8px;
                        font-size: 12px;
                        font-weight: 400;
                        width: calc(100% - 18px);

                        a {
                            text-decoration: underline;
                        }
                    }

                    p.false {
                        color: #ee3030;
                    }
                }
            }

            .btn_join {
                margin-top: 36px;
                width: 100%;
                height: 40px;
            }
        }
    }
}

@media (max-width: 1440px) {
    .login_container {
        .login_bg_box {
            display: none;
        }
        .menu_box {
            width: 100%;
        }
    }
}

@media (max-width: 1024px) {
    .login_container {
        .menu_box {
            .menu_box_inner {
                .logo_box {
                    width: 300px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text_box {
                    .text__1 {
                        font-size: 34px;
                    }
                    .text__2 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .login_container {
        .menu_box {
            .menu_box_inner {
                width: 100%;
                padding: 0 10px;
            }
        }
    }
}
