.btn_theme_bl {
  background-color: #1e69cb !important;
  font-size: 20px;
  font-weight: 700;
  border-radius: 30px;
  text-align: center;
  box-sizing: border-box;
  color: white;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.btn_theme_bl:hover {
  background-color: #74b0ff !important;
}

.btn_theme_bl_negative {
  background-color: white;
  font-size: 20px;
  font-weight: 700;
  border-radius: 30px;
  text-align: center;
  box-sizing: border-box;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.btn_theme_bl_negative:hover {
  background-color: #fee500 !important;
  color: black;
}

.btn_theme_yel {
  background-color: #fee500 !important;
  font-size: 20px;
  font-weight: 700;
  border-radius: 30px;
  text-align: center;
  box-sizing: border-box;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.btn_theme_yel:hover {
  background-color: #fff280 !important;
}

.btn_theme_gray {
  background-color: #cccccc !important;
  font-size: 20px;
  font-weight: 700;
  border-radius: 30px;
  text-align: center;
  box-sizing: border-box;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.btn_theme_gray:hover {
  background-color: #e5e5e5 !important;
}

.btn_theme_red {
  background-color: #ee3030 !important;
  font-size: 20px;
  font-weight: 700;
  border-radius: 30px;
  text-align: center;
  box-sizing: border-box;
  color: white;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.btn_theme_red:hover {
  background-color: #ee7070 !important;
}

.btn_theme_text_gray {
  text-align: left;
  color: #cccccc;
  background: none;
}

.btn_theme_text_gray:hover {
  color: #ee3030 !important;
}

.photo_box {
  position: relative;

  .photo {
    overflow: hidden;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border: 1px solid #000;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .icon.att {
    position: absolute;
    left: 50%;
    top: 110px;
    margin-left: 35px;
    background-position-x: 0;
    cursor: pointer;
  }

  .icon.att:hover {
    background-position-x: 100%;
  }

  .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: url(../assets/ui/edit_theme_bl.png) no-repeat 0 0 / auto 101%;
    vertical-align: top;
  }
}

.input_title_container,
.toggle_input_container {
  .title_box {
    font-size: 20px;
    font-weight: 700;
  }

  input,
  textarea {
    margin-top: 10px;
  }

  textarea {
    resize: none;
    height: 160px;
    padding: 10px 15px 10px 15px;
  }
}

.input_round {
  width: 100%;
  height: 40px;
  border: 1px solid #cccccc;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
}

.input_round:focus {
  border-color: black;
}

.input_round.alert {
  border: 1px solid #ee3030;
}

.toggle_input_container {
  width: 100%;
  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .toggle_btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dropdown_btn {
  width: 100%;
  height: 100%;
  border: 1px solid #cccccc;
  border-radius: 20px;
  background: none;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 30px;

  img {
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translate(0, -50%);
    transition: all 0.4s;
  }
}

.dropdown_btn.active {
  img {
    transform: translate(0, -50%) rotate(180deg);
  }
}

.dropdown_ul {
  display: none;
  width: 100%;
  margin-top: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 20px;
  z-index: 5;

  .dropdown_li {
    padding-left: 20px;
    padding-right: 20px;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .dropdown_li:hover {
    cursor: pointer;
    background: #fee500;
  }
}

.dropdown_ul.active {
  display: block;
  z-index: 50;
}

.page_container {
  width: 100%;
  height: 30px;

  .page_inner {
    height: 100%;

    .page_item_list {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .page_btn {
        width: 30px;
        height: 30px;
        margin: 2px;
        background: none;
        color: #cccccc;
        box-sizing: border-box;
      }

      .page_btn.disabled {
        cursor: auto;
      }

      .page_btn:not(.disabled):hover {
        color: black;
      }

      .page_item {
        background: none;
        width: 30px;
        height: 30px;
        font-size: 16px;
        font-weight: 700;
        border-radius: 20px;
        line-height: 30px;
        color: #cccccc;
        margin: 2px;
        box-sizing: border-box;
      }

      .page_item:hover {
        color: black;
      }

      .page_item.active {
        background-color: #fee500;
        color: black;
      }
    }
  }
}

.drop_menu_box {
  width: 150px;
  position: absolute;
  // top: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  z-index: 1;

  .drop_menu_btn_list {
    margin-top: 15px;
    margin-bottom: 15px;

    .drop_menu_btn {
      padding-left: 14px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      text-align: left;
      box-sizing: border-box;

      img {
        width: 15px;
        height: 17.3px;
      }

      span {
        font-size: 16px;
        margin-left: 11px;
      }
    }

    .drop_menu_btn:hover {
      background-color: #fee500;
      cursor: pointer;
    }
  }
}

.icon.att {
  position: absolute;
  background-position-x: 0;
  cursor: pointer;
}

.icon.att:hover {
  background-position-x: 100% !important;
}

.icon {
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
}

.drop_menu_btn_list {
  margin-top: 15px;
  margin-bottom: 15px;

  .drop_menu_btn {
    padding-left: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    text-align: left;
    box-sizing: border-box;

    img {
      width: 15px;
      height: 17.3px;
    }

    span {
      font-size: 16px;
      margin-left: 11px;
      width: 100%;
    }
  }

  .drop_menu_btn:hover {
    background-color: #fee500;
    cursor: pointer;
  }
}
